@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://site-assets.fontawesome.com/releases/v6.5.1/css/sharp-thin.css');
@import url('https://site-assets.fontawesome.com/releases/v6.5.1/css/sharp-solid.css');
@import url('https://site-assets.fontawesome.com/releases/v6.5.1/css/sharp-regular.css');
@import url('https://site-assets.fontawesome.com/releases/v6.5.1/css/sharp-light.css');
@import url('https://site-assets.fontawesome.com/releases/v6.5.1/css/all.css');

* {
  font-family: 'Poppins', sans-serif;
}

.main {
  margin-top: 4.5rem;
  padding: 20px;
  margin-bottom: 3rem;
}

.position-relative {
  position: relative !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.rounded {
  border-radius: 5px !important;
}
.team {
  -webkit-transition: all .5s;
  transition: all .5s;
}
.shadow {
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, .15) !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, .15) !important;
}

.team .team-person img {
  -webkit-transition: all .5s;
  transition: all .5s;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
img, svg {
  vertical-align: middle;
}

a {
  text-decoration: none !important;
}