/* Header.css */

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    height: 4.5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 3rem;
    background-color: #fffc;
    transition: left .2s;
    display: flex;
    align-items: center;
    -webkit-backdrop-filter: saturate(180%) blur(22px);
    backdrop-filter: saturate(180%) blur(22px);
    border-bottom: 1px solid #dfe7ef;
}

.company-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
}

.logo {
    height: 35px;
}

.title {
    font-size: x-large;
    font-weight: 900;
}

nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0;
    margin: 0;
}

nav ul li {
    margin: 0;
}

nav ul li a {
    color: black;
    text-decoration: none;
    font-size: 16px;
}

nav ul li a:hover {
    color: #5d6ef1;
    text-decoration: none;
}

.logout {
    cursor: pointer;
}

.logged-in-as {
    font-size: x-small;
}